import React from "react";

import Layout from "../layouts/layout";
import DeconnexionForm from "../components/connexion/deconnectform";
import useWindowSize from "../hooks/useWindowSize";
import { isConnected } from "../utils/utils";
import { ConnexionForm, IInputs } from "../components/connexion/connectform";
import { useUserStore } from "../store/user";
import { SubmitHandler } from "react-hook-form";
import { getUserConnected, login } from "../api/api";
import { toast } from "react-toastify";
import { setUserInfo } from "../utils/cookies";

const Connexion = () => {
    const [width, height] = useWindowSize(183);

    const { setUser } = useUserStore();

    const [state, setState] = React.useState({
        bLoaded: false,
    });

    const onLogin: SubmitHandler<IInputs> = (data) => {
        setState({
            bLoaded: true,
        });

        login(data)
            .then((res) => {
                getUserConnected()
                    .then((res) => {
                        setState({
                            bLoaded: false,
                        });

                        //@ts-ignore
                        setUserInfo(res.data);
                        setUser(res.data);
                    })
                    .catch((error) => {
                        setState({
                            bLoaded: false,
                        });

                        showErrorAuthentication();
                    });
            })
            .catch((error) => {
                setState({
                    bLoaded: false,
                });

                showErrorAuthentication();
            });
    };

    const showErrorAuthentication = () => {
        toast.error(
            "Identifiant ou Mot de Passe erroné. Si ils sont corrects, votre compte n'est pas encore actif.",
            {
                position: "top-right",
                autoClose: 5000,
            }
        );
    };
    
    if (width == 0 || height == 0) return null;

    return (
        <Layout>
            {isConnected() ? (
                <DeconnexionForm height={height}/>
            ) : (
                <ConnexionForm {...state} onLogin={onLogin} height={height} />
            )}
        </Layout>
    );
};

export default Connexion;