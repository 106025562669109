import React, { Fragment, useState } from "react";

import { toast } from "react-toastify";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Dialog, Transition } from "@headlessui/react";
import Buttons from "../bouton/button";
import { signupUser } from "../../api/api";
import { useSpaceproStore } from "../../store/spacepro";

interface IInputs {
    org_name?: string;
    first_name: string;
    last_name: string;
    email: string;
    username: string;
    password: string;
    confirm_password: string;
    service: string;
}

const yupSchema = yup
    .object({
        org_name: yup.string(),
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        email: yup.string().email().required(),
        username: yup.string().required(),
        password: yup.string().required(),
        confirm_password: yup
            .string()
            .required()
            .oneOf([yup.ref("password")]),
    })
    .required();

const Modal = ({ isOpen, setIsOpen }: any) => {
    const { profil, setProfil } = useSpaceproStore();

    // const [showhide, setShowhide] = React.useState<string>("");
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<IInputs>({
        resolver: yupResolver(yupSchema),
        defaultValues: {
            service: "",
        },
    });

    const handleshowhide = (event: React.FormEvent<EventTarget>) => {
        const getProfil = (event.target as HTMLInputElement).value;
        setProfil(getProfil);
    };

    function closeModal() {
        setIsOpen(false);
    }

    const onSignUp: SubmitHandler<IInputs> = (data) => {
        signupUser({
            ...data,
            is_active: false,
            name: `${data.first_name} ${data.last_name}`,
        })
            .then((res) => {
                if (res.data.id) {
                    toast.info(
                        "Merci pour votre inscription, votre compte sera actif d'ici 48h une fois que celui-ci sera validé par l'administrateur",
                        {
                            position: "top-right",
                            autoClose: 1000,
                        }
                    );
                    reset();
                    closeModal();
                } else {
                    showErrorSignUp(
                        "Erreur lors de l'inscription. Veuillez contacter un administrateur."
                    );
                }
            })
            .catch((error) => {
                let msg = "";
                let data = error.response.data;
                if (data && typeof data == "object") {
                    let aMessage = Object.values(data);
                    aMessage = aMessage.map((dtMessage: any) =>
                        dtMessage.join("; ")
                    );
                    msg = aMessage.join("; ");
                }
                showErrorSignUp(`Erreur lors de l'inscription. ${msg}`);
            });
    };

    const showErrorSignUp = (msg: string) => {
        toast.error(msg, {
            position: "top-right",
            autoClose: 2000,
        });
    };

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="tw-relative tw-z-30"
                    onClose={closeModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="tw-ease-out tw-duration-300"
                        enterFrom="tw-opacity-0"
                        enterTo="tw-opacity-100"
                        leave="tw-ease-in tw-duration-200"
                        leaveFrom="tw-opacity-100"
                        leaveTo="tw-opacity-0"
                    >
                        <div className="tw-fixed tw-inset-0  tw-bg-opacity-25" />
                    </Transition.Child>

                    <div className="tw-fixed tw-inset-0 tw-overflow-y-auto">
                        <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4 tw-text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="tw-ease-out tw-duration-300"
                                enterFrom="tw-opacity-0 tw-scale-95"
                                enterTo="tw-opacity-100 tw-scale-100"
                                leave="tw-ease-in tw-duration-200"
                                leaveFrom="tw-opacity-100 tw-scale-100"
                                leaveTo="tw-opacity-0 tw-scale-95"
                            >
                                <form onSubmit={handleSubmit(onSignUp)}>
                                    <Dialog.Panel
                                        style={{
                                            backgroundColor: "rgba(70,150,94)",
                                            zIndex: 100,
                                        }}
                                        className="tw-w-full tw-bg-opacity-95 tw-max-w-xl tw-transform tw-overflow-hidden tw-rounded-2xl tw-p-6 tw-text-left tw-align-middle tw-shadow-xl tw-transition-all"
                                    >
                                        <Dialog.Title
                                            as="h3"
                                            className="tw-text-lg tw-font-medium tw-leading-6 tw-text-white tw-text-center"
                                        >
                                            Espace d'inscription
                                        </Dialog.Title>
                                        <div className="tw-mt-2 tw-space-y-2">
                                            <select
                                                className="tw-select tw-rounded-none tw-h-10 tw-select-sm tw-select-ghost tw-w-full focus:tw-outline-none focus:tw-ring-0"
                                                style={{
                                                    color: "#ED6E19",
                                                    background: "white",
                                                    boxShadow:
                                                        "inset 0 4px 4px rgba(0, 0, 0, 0.2), inset 0 -4px 4px rgba(0, 0, 0, 0.2)",
                                                }}
                                                onChange={(e) =>
                                                    handleshowhide(e)
                                                }
                                                value={profil}
                                            >
                                                <option
                                                    disabled
                                                    value={
                                                        profil == "DEFAULT"
                                                            ? profil
                                                            : ""
                                                    }
                                                    className="tw-bg-secondary tw-text-white tw-text-lg  "
                                                >
                                                    Préciser votre profil
                                                </option>
                                                <option
                                                    value={
                                                        profil == "entreprise"
                                                            ? profil
                                                            : "entreprise"
                                                    }
                                                    className="tw-bg-secondary tw-text-white tw-text-lg"
                                                >
                                                    Entreprise
                                                </option>
                                                <option
                                                    value={
                                                        profil == "gestionnaire"
                                                            ? profil
                                                            : "gestionnaire"
                                                    }
                                                    className="tw-bg-secondary tw-text-white tw-text-lg"
                                                >
                                                    Gestionnaire
                                                </option>
                                                <option
                                                    value={
                                                        profil == "partenaire"
                                                            ? profil
                                                            : "partenaire"
                                                    }
                                                    className="tw-bg-secondary tw-text-white tw-text-lg"
                                                >
                                                    Partenaire
                                                </option>
                                            </select>
                                            {(profil === "entreprise" ||
                                                profil === "partenaire" ||
                                                profil === "gestionnaire") && (
                                                <input
                                                    type="text"
                                                    placeholder="Nom de l'organisme"
                                                    className="tw-input  focus:tw-outline-none focus:tw-ring-0 tw-h-10 tw-input-sm tw-input-bordered tw-input-warning tw-w-full tw-text-slate-700 tw-border-slate-600 tw-rounded-none"
                                                    {...register("org_name")}
                                                />
                                            )}
                                            <div className="tw-flex-col tw-space-x-0 tw-space-y-2 sm:tw-flex sm:tw-flex-row sm:tw-space-y-0 sm:tw-space-x-2 tw-justify-between">
                                                <input
                                                    type="text"
                                                    placeholder="Nom"
                                                    className="tw-input  focus:tw-outline-none focus:tw-ring-0 tw-h-10 tw-input-sm tw-input-bordered tw-input-warning tw-w-full tw-text-slate-700 tw-border-slate-600 tw-rounded-none"
                                                    {...register("last_name")}
                                                />
                                                <input
                                                    type="text"
                                                    placeholder="Prénom"
                                                    className="tw-input  focus:tw-outline-none focus:tw-ring-0 tw-h-10 tw-input-sm tw-input-bordered tw-input-warning tw-w-full tw-text-slate-700 tw-border-slate-600 tw-rounded-none"
                                                    {...register("first_name")}
                                                />
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Adresse mail"
                                                className="tw-input  focus:tw-outline-none focus:tw-ring-0 tw-h-10 tw-input-sm tw-input-bordered tw-input-warning tw-w-full tw-text-slate-700 tw-border-slate-600 tw-rounded-none"
                                                {...register("email")}
                                            />
                                            <input
                                                type="text"
                                                placeholder="Identifiant"
                                                className="tw-input  focus:tw-outline-none focus:tw-ring-0 tw-h-10 tw-input-sm tw-input-bordered tw-input-warning tw-w-full tw-text-slate-700 tw-border-slate-600 tw-rounded-none"
                                                {...register("username")}
                                            />
                                            <input
                                                type="password"
                                                placeholder="Nouveau Mot de passe"
                                                className="tw-input  focus:tw-outline-none focus:tw-ring-0 tw-h-10 tw-input-sm tw-input-bordered tw-input-warning tw-w-full tw-text-slate-700 tw-border-slate-600 tw-rounded-none"
                                                {...register("password")}
                                            />
                                            <input
                                                type="password"
                                                placeholder="Confirmer le Mot de passe"
                                                className="tw-input  focus:tw-outline-none focus:tw-ring-0 tw-h-10 tw-input-sm tw-input-bordered tw-input-warning tw-w-full tw-text-slate-700 tw-border-slate-600 tw-rounded-none"
                                                {...register(
                                                    "confirm_password"
                                                )}
                                            />
                                        </div>
                                        <a
                                            href="#"
                                            className="tw-text-xs"
                                            style={{ color: "#F19251" }}
                                        >
                                            Acceptez les conditions
                                            d'utilisateur
                                        </a>
                                        <div className="tw-w-full sm:tw-w-7/12">
                                            <div className="tw-flex tw-flex-col sm:tw-flex sm:tw-flex-row sm:tw-justify-between">
                                                <Buttons
                                                    textButton="Inscription"
                                                    classN="tw-btn tw-btn-sm tw-font-normal tw-mt-2 tw-w-full tw-text-xs sm:tw-w-40 sm:tw-text-sm lg:tw-px-4 tw-rounded-xl tw-text-white tw-font-bold tw-bg-secondary hover:tw-bg-secondary_hover"
                                                />
                                                <span
                                                    className="tw-hidden sm:tw-flex tw-items-center"
                                                    style={{ color: "#F19251" }}
                                                >
                                                    |
                                                </span>

                                                <button
                                                    className="tw-btn tw-border-0 tw-btn-sm tw-font-normal tw-rounded-xl tw-bg-inherit tw-border-none tw-mt-0 tw-text-xs sm:tw-mt-2 tw-px-0 hover:tw-bg-slate-700 tw-text-white"
                                                    color="ghost"
                                                    onClick={() => closeModal()}
                                                >
                                                    Connectez-vous
                                                </button>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </form>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default Modal;
