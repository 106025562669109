import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export default function Connexion() {
    const data = useStaticQuery(graphql`
        query {
            directus {
                contents(
                    filter: {
                        code: { code: { _eq: "connexion-connecter" } }
                        page: { code: { _eq: "connexion" } }
                    }
                ) {
                    text
                    id
                    file {
                        imageFile {
                            publicURL
                        }
                        id
                    }
                }
            }
        }
    `);

    const contents = data.directus.contents;
    let content;
    if (contents.length) {
        content = contents[0];
    }

    return (
        <div className="tw-text-center tw-py-4">
            <div
                className="tw-p-5 tw-text-black"
                dangerouslySetInnerHTML={{ __html: content?.text }}
            ></div>
        </div>
    );
}
