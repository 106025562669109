import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useSpaceproStore } from "../../store/spacepro";
import Modal from "../modal/modal";
import Connexion from "./connexion";

export interface IInputs {
    username: string;
    password: string;
}

interface IProps {
    onLogin: SubmitHandler<IInputs>;
    bLoaded: boolean;
    height: number;
}

export function ConnexionForm({ onLogin, bLoaded, height }: IProps) {
    const [isOpenSignIn, setOpenSignIn] = React.useState<boolean>(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IInputs>();
    const { profil, setProfil } = useSpaceproStore();

    const change = (event: React.FormEvent) => {
        let selectedValue = (event.target as any).value;
        setProfil(selectedValue);
    };

    return (
        <div
            className="tw-border-t-2 tw-border-t-black tw-bg-white tw-text-black tw-h-[67vh] tw-border-b-2 tw-border-solid tw-border-b-[#67648A] sm:tw-h-[60vh]"
            style={{
                height: height + "px",
                width: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            <Connexion />

            <p className="tw-text-sm sm:tw-text-lg tw-text-center tw-pb-3 tw-font-bold">
                Espace connection
            </p>
            <div className="tw-flex tw-justify-center tw-w-full tw-pb-3">
                <form
                    onSubmit={handleSubmit(onLogin)}
                    className="form tw-flex tw-flex-col tw-w-64 sm:tw-w-4/12 sm:tw-flex sm:tw-justify-center tw-space-y-2"
                >
                    <select
                        className="tw-select tw-rounded-none tw-h-10 tw-select-sm tw-w-full focus:tw-outline-none focus:tw-ring-0"
                        style={{
                            color: "#ED6E19",
                            boxShadow:
                                "inset 0 4px 4px rgba(0, 0, 0, 0.2), inset 0 -4px 4px rgba(0, 0, 0, 0.2)",
                        }}
                        onChange={(e) => change(e)}
                        value={profil}
                    >
                        <option
                            disabled
                            value={profil === "DEFAULT" ? profil : ""}
                            className="tw-bg-secondary tw-text-white tw-text-lg tw-border-b tw-border-white"
                            style={{ borderRadius: "0" }}
                        >
                            Préciser votre profil
                        </option>
                        <option
                            value={
                                profil === "entreprise" ? profil : "entreprise"
                            }
                            className="tw-bg-secondary tw-text-white tw-text-lg tw-border-b tw-border-white"
                            style={{ borderRadius: "0" }}
                        >
                            Entreprise
                        </option>
                        <option
                            value={
                                profil === "gestionnaire"
                                    ? profil
                                    : "gestionnaire"
                            }
                            className="tw-bg-secondary tw-text-white tw-text-lg tw-border-b tw-border-white"
                            style={{ borderRadius: "0" }}
                        >
                            Gestionnaire
                        </option>
                        <option
                            value={
                                profil === "partenaire" ? profil : "partenaire"
                            }
                            className="tw-bg-secondary tw-text-white tw-text-lg tw-border-b tw-border-white"
                            style={{ borderRadius: "0" }}
                        >
                            Partenaire
                        </option>
                    </select>
                    <input
                        {...register("username", { required: true })}
                        type="text"
                        placeholder="Identifiant ou Adresse mail"
                        className="tw-input  focus:tw-outline-none focus:tw-ring-0 tw-h-10 tw-input-sm tw-input-bordered tw-input-warning tw-w-full tw-text-slate-700 tw-border-slate-600 tw-rounded-none"
                    />
                    <input
                        {...register("password", { required: true })}
                        type="password"
                        placeholder="Mot de passe"
                        className="tw-input tw-h-10 tw-input-sm focus:tw-outline-none focus:tw-ring-0 tw-input-bordered tw-input-warning tw-w-full tw-text-slate-700 tw-border-slate-600 tw-rounded-none"
                    />
                    <a
                        href="#"
                        className="tw-text-xs"
                        style={{ color: "#F19251" }}
                    >
                        Mot de passe oublié ?
                    </a>
                    <div className="tw-w-full tw-pb-3 ">
                        <div className="tw-flex tw-flex-col sm:tw-flex sm:tw-flex-row tw-gap-5">
                            <button
                                disabled={bLoaded}
                                className="tw-btn tw-btn-sm tw-border-0 tw-font-normal tw-mt-2 tw-w-full tw-text-xs sm:tw-w-48 sm:tw-text-sm lg:tw-px-4 tw-rounded-xl tw-text-white tw-bg-secondary hover:tw-bg-secondary_hover disabled:tw-bg-secondary"
                            >
                                Connectez-vous
                            </button>
                            <span
                                className="tw-hidden sm:tw-flex tw-items-center"
                                style={{ color: "#F19251" }}
                            >
                                |
                            </span>

                            <button
                                className=" tw-font-normal tw-rounded-xl tw-bg-inherit tw-border-none tw-mt-0 tw-text-base sm:tw-mt-2 tw-px-0  tw-text-black"
                                color="ghost"
                                onClick={() => setOpenSignIn(true)}
                            >
                                INSCRIVEZ-VOUS
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <Modal setIsOpen={setOpenSignIn} isOpen={isOpenSignIn} />
        </div>
    );
}
